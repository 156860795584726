<template>
    <div class="view">
        <div class="pa24">
            <div class="flex-a-b-c">
                <div>
                    <el-radio style="margin-right: 0;" v-model="withdrawalStatusValue" label="all" @change="searchFun"
                        border>
                        全部记录
                    </el-radio>
                    <el-radio style="margin-right: 0;" v-model="withdrawalStatusValue" :label="id" @change="searchFun"
                        v-for="(name, id) in withdrawalStatus" :key="id" border>
                        {{ name }}
                    </el-radio>
                    <!-- <el-input class="w200 ml10 mb10 mr10" placeholder="请输入订单编号" v-model="orderCode" />
                    <el-button type="primary" @click="searchFun">搜索</el-button> -->
                </div>
                <div><el-button type="primary" @click="showModelFun">设置手续费</el-button></div>
            </div>
            <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
                <template v-slot:table>
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <el-table :data="props.row.responseList" show-summary border :summary-method="getSummaries">
                                <el-table-column type="index" align="center" label="序号" width="80" />
                                <el-table-column prop="goodsName" align="center" label="商品名称" show-overflow-tooltip />
                                <el-table-column prop="fromUserName" align="center" label="购买用户" show-overflow-tooltip />
                                <el-table-column prop="createTime" align="center" label="购买时间" show-overflow-tooltip />
                                <el-table-column prop="payPrice" align="center" label="商品价格" show-overflow-tooltip >
                                    <template slot-scope="scope">
                                        {{ scope.row.payPrice/100 }}元
                                    </template>
                                </el-table-column>
                                <el-table-column prop="costPrice" align="center" label="成本价" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        {{ scope.row.costPrice }}元
                                    </template>
                                </el-table-column>
                                <el-table-column prop="balance" align="center" label="分佣金额" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        {{ scope.row.balance }}元
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="amount flex-a-b-c mt10" title="提现金额需要扣除手续费和成本价">
                                <div>
                                    <div>
                                        <span class="mr10">返佣金额 <span class="text-decoration">{{ props.row.amount }}</span>元</span>
                                        <span>手续费 {{ (props.row.feeScale ? props.row.feeScale : 0) * 100 }}%</span>
                                    </div>
                                    <div>提现金额 {{ props.row.amount - (props.row.fee ? props.row.fee : 0) | getPrice2}}</div>
                                </div>
                                <div class="flex-a-c" v-if="props.row.approvalStatus === 0">
                                    <customPopconfirm confirm-button-text="确定" cancel-button-text="取消"
                                        @confirm="paymentFun(props.row, 2)" icon="el-icon-info" title="确认要驳回该提现吗？">
                                        <el-button type="danger" class="mr10" slot="reference" size="mini">驳 回</el-button>
                                    </customPopconfirm>
                                    <customPopconfirm confirm-button-text="确定" cancel-button-text="取消"
                                        @confirm="paymentFun(props.row, 1)" icon="el-icon-info" title="确认要继续打款吗？">
                                        <el-button type="primary" slot="reference" size="mini">打 款</el-button>
                                    </customPopconfirm>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column type="index" align="center" label="序号" width="80"/> -->
                    <el-table-column prop="name" align="center" label="提现用户" show-overflow-tooltip />
                    <el-table-column prop="phone" align="center" label="联系方式" show-overflow-tooltip />
                    <el-table-column prop="createdTime" align="center" label="申请时间" show-overflow-tooltip />
                    <el-table-column prop="name" align="center" label="状态" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div style="color:#E6A23C;" v-if="scope.row.approvalStatus === 0">待审核</div>
                            <div class="cblue" v-if="scope.row.approvalStatus === 1">已打款</div>
                            <div class="cred" v-if="scope.row.approvalStatus === 2">已驳回</div>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
            <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="设置手续费" :visible.sync="showModel" :destroy-on-close="true" 
                width="500px" center>
                <el-form ref="refundData" label-width="100px">
                    <el-form-item label="手续费" prop="feeScale">
                        <el-input v-model="inputFeeScale" class="suffixInput" max="100" type="number" style="width: 110px" >
                                <template slot="append">%</template>
                            </el-input>
                        <el-slider v-model="sliderFeeScale" :min="0" :max="100">
                        </el-slider>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button class="mr10" @click="showModel = false">取 消</el-button>
                    <el-button type="primary" slot="reference" :loading="loadingBtn" @click="setAccountFee">提 交</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import { getMemberWithdrawalsRecordList, updateMemberWithdrawalsRecord,getAccountFee, setAccountFee } from "@/api/withdrawal";
export default {
    name: "enterOrder",
    components: {
        commonTable,
        customPopconfirm
    },
    data() {
        return {
            //列表配置
            withdrawalStatus: {
                0: "待审核", 1: "已打款", 2: "已驳回"
            },
            withdrawalStatusValue: "all",
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            tableData: [],
            userInfo:this.$store.state.loginRoot.userInfo,
            accountType: 9,
            //手续费设置
            showFeeScale:0,//通过接口获取到的手续费
            inputFeeScale:0,//文本框的手续费
            sliderFeeScale:0,//拖动条的手续费
            showModel: false,//表单显示状态
            loadingBtn: false,//按钮加载状态
        };
    },
    watch:{
        inputFeeScale(val){
            this.inputFeeScale=val>100?100:Number(val)
            this.sliderFeeScale=val>100?100:Number(val);
        },
        sliderFeeScale(val){
            this.inputFeeScale=Number(val);
        },
    },
    created() {
        this.getList();
        this.getAccountFee();
    },
    methods: {
        /**@method 显示设置手续费表单 */
        showModelFun(){
            this.inputFeeScale=this.showFeeScale;
            this.sliderFeeScale=this.showFeeScale;
            this.showModel=true;
        },
        /**@method 获取手续费 */
        getAccountFee() {
            getAccountFee({
                accountType: this.accountType
            }).then(res => {
                if (res.code === 200) {
                    this.showFeeScale = res.data.feeScale * 100;
                }
            })
        },
        /**@method 设置手续费 */
        setAccountFee() {
            this.loadingBtn = true;
            setAccountFee({
                accountType: this.accountType,
                feeScale: this.inputFeeScale/100
            }).then(res => {
                this.loadingBtn = false;
                if (res.code === 200) {
                    this.$message({
                        type: "success",
                        message: "设置完成"
                    })
                    this.showModel=false;
                    this.getAccountFee();
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                this.loadingBtn = false;
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        paymentFun(data, status) {
            updateMemberWithdrawalsRecord({ recordId: data.recordId, approvalStatus: status }).then(res => {
                if (res.code === 200) {
                    if (status === 1) {
                        this.$message({
                            type: "success",
                            message: "打款成功"
                        })
                    } else {
                        this.$message({
                            type: "success",
                            message: "已驳回"
                        })
                    }
                    this.getList();
                } else {
                    this.$message.error(err.message)
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        /**@method 计算合计 */
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '总价';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            if(column.property==="payPrice"){
                                return Number((prev).toFixed(2)) + (curr/100); 
                            }else{
                                return Number((prev).toFixed(2)) + curr;
                            }
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] += '元';
                } else {
                    sums[index] = ' ';
                }
            });

            return sums;
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                isPage: 1,
                accountType:this.accountType
            };
            if (this.withdrawalStatusValue != 'all') {
                params.approvalStatus = this.withdrawalStatusValue
            }
            try {
                this.loading = true;
                let res = await getMemberWithdrawalsRecordList(params);
                this.loading = false;

                const { data } = res;
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
    },
};
</script>

<style lang="scss" scoped>
.amount {
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #EBEEF5;
}

.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>